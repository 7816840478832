/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-case-declarations */
/* eslint-disable default-case */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';

import ChatBot from './ChatBot';

import isHotkey from 'is-hotkey';

import { cx, css } from '@emotion/css';
import { Editable, withReact, useSlate, Slate, useFocused, useSelected, ReactEditor } from 'slate-react';
import { Col, Row } from 'reactstrap';
import { Box, Grid, Modal, Tooltip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Editor, createEditor, Range, Transforms, Text, Path } from 'slate';
import { withHistory } from 'slate-history';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Button, ColorPicker, Icon } from './Components';
import withCustomNormalize from '../../utils/normalize';
import {
  Element,
  FormatButton,
  Leaf,
  Portal,
  disallowedIconsOn,
  extractAudioUrls,
  formatEditorContent,
  getFirstTextNodeAtSelection,
  getIcon,
  // playAudioSequentially,
  toggleBlock,
  toggleMark,
  withEmbeds,
} from '../../utils/common';
import {
  CHARACTERS,
  DuoDialogueCharacters,
  HOTKEYS,
  VoiceMessageDialogue,
  VoiceMessageDialogue2,
  artifactList,
  blocks,
  initialValue,
} from '../../utils/constants';

import { withEditableVoids, withTables } from './customElements';
import useSelection from '../../hooks/useSelection';
import useAddCommentThreadCallback from '../../hooks/useAddCommentThreadCallback';
import { activeCommentThreadIDAtom } from '../../utils/CommentState';
import CommentThreadPopover from './commenting/CommentThreadPopover';
import {
  getSmallestCommentThreadAtTextNode,
  initializeStateWithAllCommentThreads,
  insertCommentThread,
  shouldAllowNewCommentThreadAtSelection,
} from '../../utils/EditorCommentUtils';
import useSettings from '../../hooks/useSettings';
import Navbar from './components/navbar/Navbar';
import ScriptBoard from './components/ScriptBoard/ScriptBoard';

import DrawerMenu from './components/RightDrawer/components/DrawerMenu';
import LeftDrawerMenu from './components/LeftDrawer/LeftDrawerMenu';
import BottomLeftItem from './components/EditorItems/bottomleft/BottomLeftItem';
import BottomRightItem from './components/EditorItems/bottomright/BottomRightItem';
import ScriptOutline from './components/ScriptOutline/ScriptOutline';
import useScript from './useScript';
import { useFetch } from '../../hooks/useFetch';
import { debounce, set } from 'lodash';
import GettingStartedPage from './GettingStarted';
import VoiceRecorderComponent from '../desk/Sidebar/dashboard/VoiceRecorder';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import VoiceMessage from './components/audioPlayer/AudioPlayer';
import MoodGatheringModal from './components/MoodGatheringModal';

import { styled } from '@mui/material/styles';
import axios from 'axios';

function ScriptEditors({ document, onChange,scriptId ,}) {
  const { id } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [scriptResponse, loading] = useScript(id, refresh);
  const script = scriptResponse?.data || null;
  console.log('scriptttttt', script);
  const userMood = script?.userMood || null;
  const [currentScriptContent, setCurrentScriptContent] = useState(script?.content || initialValue);
  const [selectedText, setSelectedText] = useState(null);
  const [intEXt, setIntEXt] = useState(null);
  const [playingIndex, setPlayingIndex] = useState(null);
  const intExtRef = useRef();

  // auto-generate button style
  const AutoGenerate = styled(Button)({
    color: '#fff',
    '&:hover': {
      backgroundColor: '#e55d1a',
    },
    marginTop: '2rem',
    marginBottom: '3rem',
    position: 'fixed',
    bottom: '30px',
    left: '35px',
    outline: 'none',
    border: 'none',
    height: '45px',
    width: 'auto',
    display: 'flex',
    cursor: 'pointer',
    background: '#fe6d29',
    padding: '10px 20px',
    borderRadius: '8px',
    fontSize: '15px',
    
  });
  const [showModal, setShowModal] = useState(false);
  const [showAutGenerate, setShowAutGenerate] = useState(true);
  // Handle the click event to open the modal


const [values, setValues] = useState({}); // Store values for all characters
const [characterName, setCharacterName] = useState('');

const handleChange = (questionId, value) => {
  setValues((prev) => ({
    ...prev,
    [characterName]: {
      ...prev[characterName],
      [questionId]: value,
    },
  }));
};
const [showDetailsModal, setShowDetailsModal] = useState(false);

const [charactersData, setCharactersData] = useState({});

const [generalInfo, setGeneralInfo] = useState({});
  useEffect(() => {
    // Retrieve AI-generated data from local storage
    const data = JSON.parse(localStorage.getItem('charactersData'));
    const generalInfo = JSON.parse(localStorage.getItem('generalInfo'));
    if (data) {
      setCharactersData(data.values);
    }
    if (generalInfo) {
      setGeneralInfo(generalInfo.generalInfo);
    }
  }, []);

  const apiKey = process.env.REACT_APP_API_kEY || '';

  const handleAutoGenerateClick = async () => {
    if (!generalInfo.pageTarget || generalInfo.pageTarget <= 0) {
      alert('Please set a valid page target.');
      return;
    }
  
    // eslint-disable-next-line no-plusplus
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-3.5-turbo',
        messages: [
          { 
            role: 'system', 
            content: `You are a screenplay writer. Here are details about the screenplay:

            Title: ${generalInfo.title}
            Genre: ${generalInfo.genre}
            Logline: ${generalInfo.logline}
            Description: ${generalInfo.description}
            Characters: ${JSON.stringify(charactersData)}

            Write a screenplay scene, keeping in mind the above details. Make sure to write the screenplay in the same style as the characters. So the characters should be the main focus of the screenplay while keeping in mind the genre, logline and description. Till the end of the screenplay. And screenplay should be upto ${generalInfo.pageTarget} pages.` 
          },
          { role: 'user', content: `Generate scene of the screenplay.` }
        ]
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        }
      }
    );


      const { data } = response;
      if (response.status !== 200) throw new Error(data.error.message);
        console.log('data response', data);
      // Editor.insertText(data.choices[0]?.message?.content || '');
      Transforms.insertText(editor, data.choices[0]?.message?.content || '');
      setShowAutGenerate(false);
  };
  
  const [questionnaireData, setQuestionnaireData] = useState(null);

  useEffect(() => {
    // Retrieve data from local storage
    const savedData = localStorage.getItem('questionnaireData');
    if (savedData) {
      setQuestionnaireData(JSON.parse(savedData));
    }

    // Alternatively, fetch data from the backend API
    fetch(`/api/getQuestionnaireData?scriptId=${scriptId}`)
      .then((response) => response.json())
      .then((data) => {
        setQuestionnaireData(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [scriptId]);


  const [audioUrls, setAudioUrls] = useState([]);

  // Extract audio URLs on component mount or when editorContent changes
  useEffect(() => {
    if (script?.content.length > 0) {
      setAudioUrls(extractAudioUrls(script?.content));
    }
  }, [script?.content]);

  const { themeMode } = useSettings();

  const editorRef = useRef(null);
  const [getStartedExpanded, setGetStartedExpanded] = useState(false);

  const updateScript = useCallback(() => {
    setRefresh(!refresh);
  }, [refresh]);
  const [dropD, setDropD] = useState(false);

  const [playingUrl, setPlayingUrl] = useState(null); // Currently playing URL
  const [isPlaying, setIsPlaying] = useState(false); // State to check if audio is playing
  const audioRef = useRef(null); // Ref to hold the audio instance
  const [sequentially, setSequentially] = useState(false);
  const playAudioSequentially = (urls) => {
    let index = 0;
    setSequentially(true);
    const playNext = () => {
      if (index < urls.length) {
        const currentUrl = urls[index];
        setPlayingUrl(currentUrl); // Update the currently playing audio URL
        const audio = new Audio(currentUrl);
        audioRef.current = audio; // Save the audio instance
        audio.play();
        audio.onended = () => {
          // eslint-disable-next-line no-plusplus
          index++;
          playNext();
        };
      } else {
        setPlayingUrl(null); // Reset when all audios are done
        setIsPlaying(false); // Update playback state
        setSequentially(false);
      }
    };

    playNext();
    setIsPlaying(true); // Update playback state
  };

  const stopPlaying = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
    }
    setPlayingUrl(null); // Clear the current playing URL
    setIsPlaying(false); // Update playback state
  };

  const [selectedItem, setSelectedItem] = useState(null);

  const selectArtifact = (item) => {
    if (item.value === 'duo-dialogue') {
      insertDuoCharacters();
      setDropD(false);
      return;
    }
    if (item.value === 'sceneHeading') {
      setDropD(false);
      const { anchor, focus } = editor.selection;
      setTimeout(() => {
        setIntEXt({ anchor, focus });
      }, 100);
    }
    if (item.value === 'voice-dialogue') {
      handleOpen();
      setDropD(false);
      return;
    }
    toggleBlock(editor, item.value);
    setDropD(false);
  };

  // useEffect(() => {

  //   if (selectedItem) {

  //   }

  // }, [selectedItem]);

  const RenderElement = (props) => {
    const { element, children } = props;

    // Use hooks here at the top level
    const selected = useSelected();
    const isFocused = useFocused();
    const audioUrl = element?.children[0]?.url;
    const alignCenter = element?.children[0]?.alignCenter;
    const isPlaying = playingUrl === audioUrl; // Compare the URL instead of index
    const path = ReactEditor.findPath(editor, element);
    const index = path ? path[0] : null;
    const [selectedIndex, setSelectedIndex] = useState(0); // Track selected artifact index

    // Handle keyboard navigation for the dropdown
    // eslint-disable-next-line consistent-return
    useEffect(() => {
      if (dropD) {
        const handleKeyDown = (event) => {
          if (event.key === 'ArrowDown') {
            event.preventDefault();
            setSelectedIndex((prevIndex) => (prevIndex === artifactList.length - 1 ? 0 : prevIndex + 1));
            setSelectedItem(artifactList[selectedIndex]);
          } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setSelectedIndex((prevIndex) => (prevIndex === 0 ? artifactList.length - 1 : prevIndex - 1));
            setSelectedItem(artifactList[selectedIndex]);
          }
          // else if (event.key === 'Enter') {
          //   event.preventDefault();
          //   selectArtifact(selectedItem);
          // }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
      }
    }, [dropD, selectedIndex]);

    const block = blocks.find((block) => block.type === element.type);
    if (block) {
      const changeGetStartedExpanded = () => {
        setGetStartedExpanded(!getStartedExpanded);
      };

      return block.renderBlock({
        element,
        children,
        audioUrl,
        alignCenter,
        changeGetStartedExpanded,
        getStartedExpanded,
        index,
        ...props,
      });
    }

    const handleIconClick = () => {
      setDropD(!dropD);
    };

    if (element.type === 'page-break' || element.type === 'page-number') {
      return <Element {...props} />;
    }

    if (element.type === 'voice-message') {
      return <VoiceMessage audioUrl={audioUrl} alignCenter={alignCenter} isPlayingProp={isPlaying} />;
    }

    return (
      <div>
        {selected && (
          <div className="dropdown">
            {element.type && !disallowedIconsOn(element.type) ? (
              <FontAwesomeIcon className="icon-main dropbtn" icon={getIcon(element.type)} onClick={handleIconClick} />
            ) : null}
            {dropD && (
              <div className="dropdown-content">
                {artifactList.map((item, idx) => (
                  <Grid
                    key={item.value}
                    container
                    className="dropdown-content-item"
                    onClick={() => {
                      // Handle icon clicks here
                      selectArtifact(item);
                    }}
                    style={{
                      backgroundColor:
                        // eslint-disable-next-line no-nested-ternary
                        // selectedIndex === idx ? '#fe6d2933' : element.type === item.value ? '#fe6d2933' : 'white',
                        element.type === item.value ? '#fe6d2933' : 'white',
                    }}
                  >
                    <Grid item xs={1} style={{ textAlign: 'left' }} />
                    <Grid item xs={8} style={{ textAlign: 'left' }}>
                      {item.name}
                    </Grid>
                    <Grid item xs={1}>
                      <a value={item.value}>
                        <FontAwesomeIcon className="mr-35 icon" icon={getIcon(item.value)} />{' '}
                      </a>
                    </Grid>
                  </Grid>
                ))}
              </div>
            )}
          </div>
        )}
        <div>
          <Element {...props} />
        </div>
      </div>
    );
  };

  const renderElement = useCallback(
    (props) => <RenderElement {...props} />,
    [
      getStartedExpanded,
      dropD,
      setDropD,
      playingUrl, // Ensure `playingUrl` is included in the dependency array
    ]
  );

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const withMentions = (editor) => {
    const { isInline, isVoid, markableVoid } = editor;

    editor.isInline = (element) => (element.type === 'mention' ? true : isInline(element));

    editor.isVoid = (element) => (element.type === 'mention' ? true : isVoid(element));

    editor.markableVoid = (element) => element.type === 'mention' || markableVoid(element);

    return editor;
  };

  const editor = useMemo(
    () => withEmbeds(withTables(withMentions(withEditableVoids(withHistory(withReact(createEditor())))))),
    []
  );

  const Menu = React.forwardRef(({ className, ...props }, ref) => (
    <div
      {...props}
      data-test-id="menu"
      ref={ref}
      className={cx(
        className,
        css`
          & > * {
            display: inline-block;
          }

          & > * + * {
            margin-left: 15px;
          }
        `
      )}
    />
  ));

  const addCommentThread = useAddCommentThreadCallback();

  const HoveringToolbar = () => {
    const ref = useRef();
    const editor = useSlate();
    const inFocus = useFocused();
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [dropD, setDropD] = useState(false); // Assuming dropD is a boolean state
    const setActiveCommentThreadID = useSetRecoilState(activeCommentThreadIDAtom);

    // Function to handle the "micro" button click
    const handleOpenConst = () => {
      handleOpen();
      return setDropD(false);
      // Add your logic here
    };

    useEffect(() => {
      const el = ref.current;
      const { selection } = editor;

      if (!el) {
        return;
      }

      if (!selection || !inFocus || Range.isCollapsed(selection) || Editor.string(editor, selection) === '') {
        el.removeAttribute('style');
        return;
      }

      const domSelection = window.getSelection();
      // Ensure domSelection has a range
      if (!domSelection || domSelection.rangeCount === 0) {
        return;
      }

      const domRange = domSelection.getRangeAt(0);
      const rect = domRange.getBoundingClientRect();
      el.style.opacity = '1';
      el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
      el.style.left = `${rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2}px`;
    }, [editor, inFocus]);

    const onColorSelect = useCallback(
      (color) => {
        Transforms.setNodes(
          editor,
          { color },
          {
            match: (node) => Text.isText(node),
            split: true,
          }
        );

        setShowColorPicker(false);
      },
      [editor]
    );

    const onInsertComment = useCallback(() => {
      const newCommentThreadID = insertCommentThread(editor, addCommentThread);
      setActiveCommentThreadID(newCommentThreadID);
      const textNode = getFirstTextNodeAtSelection(editor, selection);
      if (!textNode) {
        return;
      }

      setTimeout(() => {
        const newComment = { [`commentThread_${newCommentThreadID}`]: true };
        const selectionRange = { text: textNode?.text, ...newComment };
        setActiveCommentThreadID(getSmallestCommentThreadAtTextNode(editor, selectionRange));
      }, 200);

      setSelectedText(textNode);
    }, [editor, addCommentThread, setActiveCommentThreadID]);

    return (
      <Portal>
        <Menu
          ref={ref}
          className={css`
            padding: 8px 7px 6px;
            position: absolute;
            z-index: 1;
            top: -10000px;
            left: -10000px;
            margin-top: -6px;
            opacity: 0;
            background-color: #222;
            border-radius: 4px;
            transition: opacity 0.75s;
          `}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
        >
          <FormatButton format="bold" icon="format_bold" />
          <FormatButton format="highlight" icon="highlight" />
          <FormatButton format="italic" icon="format_italic" />
          <FormatButton format="capitalize" icon="text_fields" />
          <FormatButton format="uppercase" icon="title" />
          <FormatButton format="underline" icon="format_underlined" />
          <Button onClick={() => setShowColorPicker(!showColorPicker)}>
            <Icon>format_paint</Icon>
          </Button>
          {showColorPicker && <ColorPicker onColorSelect={onColorSelect} />}

          <Button
            reversed
            isActive={false}
            disabled={!shouldAllowNewCommentThreadAtSelection(editor, selection)}
            onMouseDown={onInsertComment}
          >
            <Icon>comment</Icon>
          </Button>
          <Button
            onMouseDown={() => {
              handleOpenConst();
            }}
          >
            <Icon>micro</Icon>
          </Button>
        </Menu>
      </Portal>
    );
  };

  // if user double click on the character name, it will open the character dialog

  const handleOnPaste = (e) => {
    // e.preventDefault();

    const data = e.clipboardData;
    // insert data into the editor
    Transforms.insertFragment(editor, data);
  };
  const [updateScriptApi] = useFetch(`api/scripts/${id}`, ``, '', 'PATCH', false);
  const [previousSelection, selection, setSelection] = useSelection(editor);

  const handleDoubleClickCharacter = (event) => {
    if (selection) {
      const currentNode = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n),
      });

      if (currentNode && currentNode[0]?.type === 'character') {
        handleOpen();
        return setDropD(false);
      }
    }
  };

  const activeCommentThreadID = useRecoilValue(activeCommentThreadIDAtom);
  const [saving, setSaving] = useState(false);

  // Add a state for the debounced function
  const debouncedUpdateScriptApi = useRef(
    debounce(async (content) => {
      setSaving(true);
      await updateScriptApi({ content });
      setSaving(false);
    }, 1000) // Adjust the delay (in milliseconds) as needed
  ).current;

  const getAllTextValues = (arr) => {
    const result = [];

    const extractText = (item) => {
      if (item.text) {
        result.push(item.text);
      }

      if (item.children) {
        item.children.forEach((child) => {
          if (child.text) {
            result.push(child.text);
          }
          if (child.children) {
            extractText(child);
          }
          // extractText(child);
        });
      }
    };

    arr.forEach((item) => {
      extractText(item);
    });

    return result.join(', ');
  };

  const onChangeLocal = useCallback(
    async (doc) => {
      const { selection } = editor;
      const textValues = getAllTextValues(doc);
      const textArray = textValues.split(',').map((item) => item.trim());

      // Split values with more than two words
      const updatedTextArray = textArray.flatMap((item) => {
        const words = item.split(' ');
        return words.length > 3 ? words : [item];
      });

      // Remove duplicates and set the updated value to characterCards
      const uniqueTextArray = [...new Set(updatedTextArray)];
      setCharacterCards([...CHARACTERS, ...uniqueTextArray]);
      setSaving(true);
      if (selection && Range.isCollapsed(selection)) {
        const [start] = Range.edges(selection);
        const wordBefore = Editor.before(editor, start, { unit: 'word' });
        const before = wordBefore && Editor.before(editor, wordBefore);
        const beforeRange = before && Editor.range(editor, before, start);
        const beforeText = beforeRange && Editor.string(editor, beforeRange);
        const beforeMatch = beforeText && beforeText.match(/^ (\w+)$/);
        const after = Editor.after(editor, start);
        const afterRange = Editor.range(editor, start, after);
        const afterText = Editor.string(editor, afterRange);
        const afterMatch = afterText.match(/^(\s|$)/);
        if (beforeMatch && afterMatch) {
          setTarget(beforeRange);
          setSearch(beforeMatch[1]);
          setIndex(0);
          return;
        }
      }

      setTarget(null);
      onChange(doc);
      setCurrentScriptContent(doc);
      setSelection(editor.selection);
      debouncedUpdateScriptApi(doc);
    },
    [onChange, setSelection, editor, debouncedUpdateScriptApi]
  );

  const editorOffsets = editorRef.current && {
    x: editorRef.current.getBoundingClientRect().x + window.pageXOffset,
    y: editorRef.current.getBoundingClientRect().y,
  };

  useEffect(() => {
    initializeStateWithAllCommentThreads(editor, addCommentThread);
  }, [editor, addCommentThread]);

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    const htmlElement = window.document.querySelector('html');
    if (themeMode === 'light') {
      htmlElement.classList.add('bgWhiteSmoke');
      htmlElement.classList.remove('html-darkMode');
    } else {
      htmlElement.classList.remove('bgWhiteSmoke');
      htmlElement.classList.add('html-darkMode');
    }
    return () => {
      htmlElement.classList.remove('bgWhiteSmoke');
      htmlElement.classList.remove('html-darkMode');
    };
  }, [themeMode]);

  const [componentShow, setComponentShow] = useState('');
  const [target, setTarget] = useState();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');
  const [characterCards, setCharacterCards] = useState(CHARACTERS || []);
  const mentionRef = useRef();
  const insertMention = (editor, character) => {
    const mention = {
      // type: 'mention',
      // character,
      children: [{ text: character }],
    };
    // Transforms.insertNodes(editor, mention);
    if (!dropD && intEXt) {
      Transforms.insertText(editor, `${character} `);
      setIntEXt(null);
      // after inserting the character, select the editor
      Transforms.insertNodes(
        editor,
        { type: 'action', children: [{ text: '' }] },
        { at: Editor.end(editor), select: true }
      );
      ReactEditor.focus(editor);
      return;
    }
    Transforms.insertText(editor, ` ${character} `);
    setTarget(null);

    // Transforms.insertFragment(editor, mention);
    // Transforms.insertText(editor, ' ');
    // Transforms.move(editor);
  };

  useEffect(() => {
    if (script) {
      const characters = script?.detailedElements?.find((e) => e.label === 'Character')?.items || [];
      const characterCards = characters.map((c) => c.content);
      // merge character cards with the default characters
      setCharacterCards([...CHARACTERS, ...characterCards]);
    }
  }, [script]);

  const chars = characterCards.filter((c) => c.toLowerCase().startsWith(search.toLowerCase())).slice(0, 10);

  const onKeyDown = useCallback(
    (event) => {
      if (target && chars.length > 0) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const prevIndex = index >= chars.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          case 'ArrowUp':
            event.preventDefault();
            const nextIndex = index <= 0 ? chars.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          case 'Tab':
            event.preventDefault();
            setTarget(null);
            break;
          case 'Enter':
            event.preventDefault();
            Transforms.select(editor, target);
            insertMention(editor, chars[index]);
            setTarget(null);
            break;
          case 'Escape':
            event.preventDefault();
            setTarget(null);
            break;
        }
      }
      if (intEXt) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const prevIndex = index >= CHARACTERS.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          case 'ArrowUp':
            event.preventDefault();
            const nextIndex = index <= 0 ? CHARACTERS.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          case 'Tab':
            event.preventDefault();
            setIntEXt(null);
            break;
          case 'Enter':
            event.preventDefault();
            Transforms.select(editor, intEXt);
            insertMention(editor, CHARACTERS[index]);
            setIntEXt(null);
            break;
          case 'Escape':
            event.preventDefault();
            setIntEXt(null);
            break;
        }
      }
    },
    [chars, editor, index, target, CHARACTERS]
  );

  useEffect(() => {
    if (target && chars.length > 0) {
      const el = mentionRef.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [chars.length, editor, index, search, target]);

  useEffect(() => {
    if (intEXt) {
      const el = intExtRef.current;
      const domRange = ReactEditor.toDOMRange(editor, intEXt);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [intEXt, editor, index, search]);

  const [previousKeyPress, setPreviousKeyPress] = useState('');
  const [isSceneHeading, setIsSceneHeading] = useState(false);
  const onKeyDownEditor = (event) => {
    const currentNode = Editor.above(editor, {
      match: (n) => Editor.isBlock(editor, n),
    });

    const firstChild = currentNode[0]?.children[0] || {};

    // Check if any key in the first child starts with 'commentThread_' and the value is true
    const hasCommentThreadKey =
      firstChild && Object.keys(firstChild).some((key) => key.startsWith('commentThread_') && firstChild[key] === true);

    const slateEditor = window.document.querySelector('.printable-div');
    // console.log current height of slateEditor

    console.log('currenHeight', slateEditor.scrollHeight);

    // if current height is greater than 936px then add a page break
    // if (slateEditor.scrollHeight > 936) {

    //   Transforms.insertNodes(
    //     editor,
    //     { type: 'page-break', children: [{ text: '' }] },
    //     { at: Editor.end(editor), select: true }
    //   );
    // }

    if (event.key === 'Backspace') {
      if (currentNode[0].type === 'voice-message') {
        // remove complete current node
        event.preventDefault();
        return;
        // Transforms.removeNodes(editor, {
        //   at: Editor.range(editor, Editor.start(editor, []), Editor.end(editor, [])),
        // });
      }
    }

    // Handle Enter key press
    if (event.key === 'Enter') {
      if (intEXt && currentNode[0].type === 'sceneHeading') {
        Transforms.insertText(editor, 'INT.');
        setIntEXt(null);
        setDropD(false);
        Transforms.insertNodes(
          editor,
          { type: 'action', children: [{ text: '' }] },
          { at: Editor.end(editor), select: true }
        );
        return;
      }

      // Handle Enter key press for scene heading and add int/ext

      if (currentNode[0].children[0].text === '' && currentNode[0].type === 'sceneHeading') {
        event.preventDefault();
        // get anchor and range
        const { anchor, focus } = editor.selection;
        setIntEXt({ anchor, focus });
        setDropD(false);
        setIsSceneHeading(true);
      }
      if (currentNode[0].children[0].text !== '' && currentNode[0].type === 'sceneHeading') {
        event.preventDefault();
        Transforms.insertNodes(
          editor,
          { type: 'action', children: [{ text: '', uppercase: false }] },
          { at: Editor.end(editor), select: true }
        );
        return;
      }
      if (currentNode[0].children[0].text !== '' && currentNode[0].type === 'action') {
        event.preventDefault();
        Transforms.insertNodes(
          editor,
          { type: 'sceneHeading', children: [{ text: '', uppercase: false }] },
          { at: Editor.end(editor), select: true }
        );
        return;
      }

      if (dropD || intEXt) {
        setDropD(false);
        setIntEXt(null);
      }

      if (currentNode[0].type === 'getting-started-element') {
        event.preventDefault();
        return;
      }

      if (dropD && currentNode[0].type === 'action') {
        event.preventDefault();
        Transforms.insertNodes(
          editor,
          { type: 'action', children: [{ text: '', uppercase: false }] },
          { at: Editor.end(editor), select: true }
        );
        setDropD(false);
        return;
      }

      // Handle Enter key press for scene heading
      if (currentNode[0].children[0].text === '' && !isSceneHeading && currentNode[0].type === 'action') {
        event.preventDefault();
        setDropD(!dropD);
        setIsSceneHeading(false);
        return;
      }
      // Handle Enter key press for scene heading
      // if (isSceneHeading && currentNode[0].type === 'sceneHeading') {
      //   event.preventDefault();
      //   setDropD(!dropD);
      //   Transforms.insertNodes(
      //     editor,
      //     { type: 'sceneHeading', children: [{ text: '', uppercase: false }] },
      //     { at: Editor.end(editor), select: true }
      //   );
      //   setIsSceneHeading(false);
      // }

      // currentNode[0].children[0] has any key which starts with commentThread_ then return

      if (hasCommentThreadKey) {
        event.preventDefault();
        Transforms.insertNodes(
          editor,
          { type: 'action', children: [{ text: '', uppercase: false }] },
          { at: Editor.end(editor), select: true }
        );
        setDropD(false);
        return;
      }

      if (currentNode[0].children[0].text === '' && currentNode[0].type === 'action') {
        event.preventDefault();
        // setDropD(!dropD);
        // Transforms.insertNodes(
        //   editor,
        //   { type: 'sceneHeading', children: [{ text: '', uppercase: false }] },
        //   { at: Editor.end(editor), select: true }
        // );
        // get anchor and range
        setIntEXt(null);
        setIsSceneHeading(false);
      }

      if (currentNode[0].type === 'character' || currentNode[0].type === 'dialogue') {
        event.preventDefault();
        const focusedElement = window.document.activeElement;
        const slateEditor = window.document.querySelector('.printable-div');

        if (previousKeyPress === 'Enter') {
          Transforms.insertNodes(
            editor,
            { type: 'character', children: [{ text: ' ', uppercase: true }] },
            { at: Editor.start(editor), select: true }
          );
        } else {
          Transforms.insertNodes(
            editor,
            { type: 'dialogue', children: [{ text: '', uppercase: false }] },
            { at: Editor.start(editor), select: true }
          );
        }

        slateEditor.contains(focusedElement);
        setPreviousKeyPress('Enter');
        return;
      }
    } else if (event.key === '.') {
      // Check if the previous text is "int" or "ext"
      const previousText = currentNode[0].children[0].text;
      console.log('previous text', currentNode[0].children[0].text);
      if (previousText === 'int') {
        event.preventDefault(); // Prevent the default dot behavior
        Transforms.setNodes(editor, { type: 'sceneHeading', children: [{ text: `INT. ` }] });
        Transforms.insertText(editor, '. ');
      } else if (previousText === 'ext') {
        event.preventDefault(); // Prevent the default dot behavior
        Transforms.setNodes(editor, { type: 'sceneHeading', children: [{ text: `EXT. ` }] });
        Transforms.insertText(editor, '. ');
      } else if (previousText === 'i/e') {
        event.preventDefault(); // Prevent the default dot behavior
        Transforms.setNodes(editor, { type: 'sceneHeading', children: [{ text: `I/E. ` }] });
        Transforms.insertText(editor, '. ');
      }
    } else {
      setPreviousKeyPress('');
      if (event.key !== 'ArrowDown' && event.key !== 'ArrowUp') {
        setIntEXt(null);
      }
    }

    // Handle other key presses
    // eslint-disable-next-line no-restricted-syntax
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        toggleMark(editor, mark);
      }
    }

    if (event.key === 'Tab' && currentNode[0].type === 'character') {
      event.preventDefault();
      const focusedElement = window.document.activeElement;
      const slateEditor = window.document.querySelector('.printable-div');
      Transforms.setNodes(
        editor,
        { type: 'action', children: [{ text: '', uppercase: true }] },
        { at: Editor.end(editor), select: true }
      );
      slateEditor.contains(focusedElement);
    }
    if (event.key === 'Tab' && currentNode[0].type === 'action') {
      event.preventDefault();
      const focusedElement = window.document.activeElement;
      const slateEditor = window.document.querySelector('.printable-div');
      Transforms.setNodes(
        editor,
        { type: 'character', children: [{ text: ' ', uppercase: true }] },
        { at: Editor.end(editor), select: true }
      );
      slateEditor.contains(focusedElement);
    }

    // Handle Tab key press
    if (
      currentNode[0].children[0].text === '' &&
      !target &&
      event.key === 'Tab' &&
      currentNode[0].type !== 'getting-started-element'
    ) {
      event.preventDefault();
      const focusedElement = window.document.activeElement;
      const slateEditor = window.document.querySelector('.printable-div');
      // Step 1: Set the node type to 'character'
      Transforms.setNodes(editor, { type: 'character', uppercase: true }, { at: Editor.end(editor), select: true });
      // Step 2: Insert the space character at the new 'character' node
      Transforms.insertText(editor, ' ', { at: Editor.end(editor) });

      slateEditor.contains(focusedElement);
    }

    // Disable CTRL+A
    // if (event.ctrlKey && event.key === 'a') {
    //   event.preventDefault();
    // }

    // Handle Shift + Enter key press
    if (event.shiftKey && event.key === 'Enter') {
      const focusedElement = window.document.activeElement;
      const slateEditor = window.document.querySelector('.printable-div');

      if (slateEditor && slateEditor.contains(focusedElement)) {
        Editor.insertBreak(editor);
      }
      event.preventDefault();
    }
  };

  const insertDuoCharacters = () => {
    const focusedElement = window.document.activeElement;
    const slateEditor = window.document.querySelector('.printable-div');
    Transforms.insertNodes(editor, DuoDialogueCharacters, { at: Editor.start(editor), select: true });

    slateEditor.contains(focusedElement);
  };

  const [recorderAudioUrl, setRecorderAudioUrl] = useState(null);

  const insertVoiceDialogue = () => {
    const { selection } = editor;
    const currentNode = Editor.above(editor, {
      match: (n) => Editor.isBlock(editor, n),
    });
    if (!recorderAudioUrl) {
      alert('Please record a voice message before inserting it into the script.');
      return;
    }

    if (selection && currentNode[0].type === 'character') {
      // Get the path of the current node to insert after
      const currentPath = ReactEditor.findPath(editor, currentNode[0]);

      // Clone the VoiceMessageDialogue to avoid modifying the original constant
      const voiceMessageDialogueConst = JSON.parse(JSON.stringify(VoiceMessageDialogue2));

      // Update the cloned object
      voiceMessageDialogueConst[0].children[0].url = recorderAudioUrl;

      // Insert the voice dialogue after the character node
      Transforms.insertNodes(editor, voiceMessageDialogueConst, { at: Path.next(currentPath) });
      return;
    }

    const focusedElement = window.document.activeElement;
    const slateEditor = window.document.querySelector('.printable-div');

    // Clone the VoiceMessageDialogue to avoid modifying the original constant
    const voiceMessageDialogueConst = JSON.parse(JSON.stringify(VoiceMessageDialogue));

    // Update the cloned object
    voiceMessageDialogueConst[0].children[0].url = recorderAudioUrl;

    // Insert the modified node into the Slate editor
    Transforms.insertNodes(editor, voiceMessageDialogueConst, { at: Editor.start(editor), select: true });

    slateEditor.contains(focusedElement);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 450,
    bgcolor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 24,
    border: 'none',
    outline: 'none',
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setRecorderAudioUrl(null);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleOpenRecordPopup = () => {
    handleOpen();
    setDropD(false);
  };

  const [showMoodModal, setShowMoodModal] = useState(false);

  useEffect(() => {
    if (script && (script.userMood || script.userMood.length > 1)) {
      setShowMoodModal(true);
    }
  }, [script]);

  return (
    <>
      <Row className={themeMode === 'light' ? 'bgWhiteSmoke' : ''}>
        {/* --------------- This is the code for Voice Recorder Dialouge Start ------------------- */}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <VoiceRecorderComponent setRecorderAudioUrl={setRecorderAudioUrl} />

            <Box display={'flex'} gap={'10px'} alignItems={'center'} width="100%" justifyContent={'center'}>
              {recorderAudioUrl && (
                <Typography
                  p={'0.5rem 2rem'}
                  sx={{
                    '&:hover': { backgroundColor: '#d0551c' },
                    fontSize: '.875rem',
                    fontWeight: '600',
                    backgroundColor: '#fe6d29',
                    color: '#fff',
                    borderRadius: '.25rem',
                    textAlign: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    insertVoiceDialogue();
                    handleClose();
                  }}
                >
                  Insert Recording
                </Typography>
              )}
              <Typography
                className="dark-text light-bg"
                onClick={handleClose}
                p={'0.5rem 2rem'}
                sx={{
                  '&:hover': { backgroundColor: '#fe6d291a' },
                  fontSize: '.875rem',
                  fontWeight: '600',
                  backgroundColor: '#00017806',
                  color: '#333',
                  borderRadius: '.25rem',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
              >
                Cancel
              </Typography>
            </Box>

            {recorderAudioUrl && (
              <Typography
                sx={{
                  fontSize: '12px',
                  marginTop: '20px',
                  textAlign: 'center',
                  color: 'red',
                }}
              >
                Please listen to the recording before inserting it into the script. If you are not satisfied with the
                recording, you can record again.
              </Typography>
            )}
          </Box>
        </Modal>

        {/* --------------- This is the code for Voice Recorder Dialouge End ------------------- */}

        <Box
          display={'flex'}
          alignItems={'center'}
          sx={{
            zIndex: '100',
            cursor: 'pointer',
            position: 'fixed',
            top: '70px',
            left: '20px',
            padding: '.5rem',
            borderRadius: '.25rem',
            backgroundColor: '#00017806',
            marginTop: '20px',
          }}
        >
          <LeftDrawerMenu script={script} refreshData={updateScript} />
        </Box>

        <Box
          display={'flex'}
          alignItems={'center'}
          sx={{
            zIndex: '100',
            cursor: 'pointer',
            position: 'fixed',
            top: '70px',
            right: '20px',
            padding: '.5rem',
            borderRadius: '.25rem',
            backgroundColor: '#00017806',
            marginTop: '20px',
          }}
        >
          <DrawerMenu script={script} refreshData={updateScript} /> <br />
          
        </Box>

        <BottomLeftItem />
        {/* <BottomRightItem /> */}

        {componentShow === 'Board' && <ScriptBoard />}
        {componentShow === 'Outline' && (
          <>
            <Navbar
              setComponentShow={setComponentShow}
              script={script}
              location={'Outline'}
              updateScriptApi={updateScriptApi}
              saving={saving}
              refreshData={updateScript}
              editor={editor}
            />
            <ScriptOutline script={script} updateScriptApi={updateScriptApi} />
          </>
        )}

        {componentShow === 'Script' && (
          <Col md={6} className="offset-md-3 editorContent">
            <Slate
              editor={editor}
              value={formatEditorContent(script?.content)}
              onChange={onChangeLocal}
              // onChange={(value) => {
              //   const isAstChange = editor.operations.some(
              //     (op) => "set_selection" !== op.type
              //   );
              //   if (isAstChange) {
              //     // Save the value to Local Storage.
              //     const content = JSON.parse(JSON.stringify(value));
              //     //console.log(content);
              //   }
              //   const { selection } = editor;
              // }}
              // value={[...initialValue]}
            >
              <Navbar
                setComponentShow={setComponentShow}
                updateScriptApi={updateScriptApi}
                script={script}
                saving={saving}
                refreshData={updateScript}
                editor={editor}
              />
              <HoveringToolbar />
              <div
                className="editor"
                ref={editorRef}
                style={{
                  marginLeft: activeCommentThreadID != null && '12%',
                }}
              >
                {activeCommentThreadID != null && editorOffsets ? (
                  <CommentThreadPopover
                    scriptData={script}
                    updateScriptApi={updateScriptApi}
                    editorOffsets={editorOffsets}
                    threadID={activeCommentThreadID}
                    selection={selection ?? previousSelection}
                    selectedText={selectedText}
                  />
                ) : null}

                <Editable
                  renderElement={renderElement}
                  onPaste={handleOnPaste}
                  renderLeaf={renderLeaf}
                  placeholder="Enter some rich text…"
                  spellCheck
                  autoFocus
                  className="printable-div"
                  onKeyDown={(event) => {
                    onKeyDown(event);
                    onKeyDownEditor(event);
                  }}
                  onClick={() => {
                    if (dropD || intEXt) {
                      setIntEXt(null);
                      setDropD(false);
                    }
                  }}
                />
                {target && chars.length > 0 && (
                  <Portal>
                    <div
                      ref={mentionRef}
                      style={{
                        top: '-9999px',
                        left: '-9999px',
                        position: 'absolute',
                        zIndex: 1,
                        padding: '3px',
                        background: 'white',
                        borderRadius: '4px',
                        boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                      }}
                      data-cy="mentions-portal"
                    >
                      {chars.map((char, i) => (
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                        <div
                          key={i}
                          onClick={() => {
                            Transforms.select(editor, target);
                            insertMention(editor, char);
                            setTarget(null);
                          }}
                          style={{
                            padding: '1px 3px',
                            borderRadius: '3px',
                            background: i === index ? '#B4D5FF' : 'transparent',
                          }}
                        >
                          {char}
                        </div>
                      ))}
                    </div>
                  </Portal>
                )}
              </div>
            </Slate>
          </Col>
        )}
        {componentShow !== 'Script' && componentShow !== 'Board' && componentShow !== 'Outline' && (
          <Col md={6} className="offset-md-3 editorContent">
            {script?.content?.length > 0 && (
              <div onDoubleClick={handleDoubleClickCharacter}>
                <Slate editor={editor} value={formatEditorContent(script?.content)} onChange={onChangeLocal}>
                  <Navbar
                    setComponentShow={setComponentShow}
                    script={script}
                    updateScriptApi={updateScriptApi}
                    saving={saving}
                    editorRef={editorRef}
                    refreshData={updateScript}
                    editor={editor}
                  />
                  {audioUrls.length > 0 && (
                    <Tooltip title={isPlaying ? 'Pause Audios' : 'Play Audios'} arrow placement="bottom-end">
                      <div
                        className="audio-control-button"
                        onClick={() => {
                          if (isPlaying) {
                            stopPlaying();
                          } else {
                            playAudioSequentially(audioUrls);
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} className="audio-icon" />
                      </div>
                    </Tooltip>
                  )}
                  <div className={activeCommentThreadID != null && 'marginLeft'}>
                    <GettingStartedPage
                      getStartedExpanded={getStartedExpanded}
                      setGetStartedExpanded={setGetStartedExpanded}
                      script={script}
                      updateScriptApi={updateScriptApi}
                    />
                  </div>
                  <HoveringToolbar />
                  <div
                    className="editor"
                    ref={editorRef}
                    style={{
                      marginLeft: activeCommentThreadID != null && '12%',
                    }}
                  >
                    {activeCommentThreadID != null && editorOffsets ? (
                      <CommentThreadPopover
                        handleOpenRecordPopup={handleOpenRecordPopup}
                        scriptData={script}
                        updateScriptApi={updateScriptApi}
                        editorOffsets={editorOffsets}
                        threadID={activeCommentThreadID}
                        selection={selection ?? previousSelection}
                        selectedText={selectedText}
                      />
                    ) : null}

                    <Editable
                      renderElement={renderElement}
                      onPaste={handleOnPaste}
                      renderLeaf={renderLeaf}
                      placeholder="Start Typing"
                      renderPlaceholder={({ children, attributes }) => <p {...attributes}>{children}</p>}
                      spellCheck
                      autoFocus
                      className="printable-div"
                      onKeyDown={(event) => {
                        onKeyDown(event);
                        onKeyDownEditor(event);
                      }}
                      onClick={() => {
                        if (dropD || intEXt) {
                          setIntEXt(null);
                          setDropD(false);
                        }
                      }}
                    />
                    {intEXt && (
                      <Portal>
                        <div
                          ref={intExtRef}
                          style={{
                            top: '-9999px',
                            left: '-9999px',
                            position: 'absolute',
                            zIndex: 1,
                            padding: '3px',
                            background: 'white',
                            borderRadius: '4px',
                            boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                          }}
                          data-cy="mentions-portal"
                        >
                          {CHARACTERS.slice(0, 3).map((char, i) => (
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                            <div
                              key={i}
                              onClick={() => {
                                Transforms.select(editor, target);
                                insertMention(editor, char);
                                setIntEXt(null);
                              }}
                              style={{
                                padding: '1px 3px',
                                borderRadius: '3px',
                                background: i === index ? '#B4D5FF' : 'transparent',
                              }}
                            >
                              {char}
                            </div>
                          ))}
                        </div>
                      </Portal>
                    )}
                    {target && chars.length > 0 && (
                      <Portal>
                        <div
                          ref={mentionRef}
                          style={{
                            top: '-9999px',
                            left: '-9999px',
                            position: 'absolute',
                            zIndex: 1,
                            padding: '3px',
                            background: 'white',
                            borderRadius: '4px',
                            boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                          }}
                          data-cy="mentions-portal"
                        >
                          {chars.map((char, i) => (
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                            <div
                              key={i}
                              onClick={() => {
                                Transforms.select(editor, target);
                                insertMention(editor, char);
                                setTarget(null);
                              }}
                              style={{
                                padding: '1px 3px',
                                borderRadius: '3px',
                                background: i === index ? '#B4D5FF' : 'transparent',
                              }}
                            >
                              {char}
                            </div>
                          ))}
                        </div>
                      </Portal>
                    )}
                  </div>
                </Slate>
              </div>
            )}
            {script?.content?.length === 0 && (
              <Slate editor={editor} value={document} onChange={onChangeLocal}>
                <Navbar
                  setComponentShow={setComponentShow}
                  script={script}
                  updateScriptApi={updateScriptApi}
                  saving={saving}
                  refreshData={updateScript}
                  editor={editor}
                />

                <GettingStartedPage
                  getStartedExpanded={getStartedExpanded}
                  setGetStartedExpanded={setGetStartedExpanded}
                  script={script}
                  updateScriptApi={updateScriptApi}
                />

                <HoveringToolbar />
                <div
                  className="editor"
                  ref={editorRef}
                  style={{
                    marginLeft: activeCommentThreadID != null && '12%',
                  }}
                >
                  {activeCommentThreadID != null && editorOffsets ? (
                    <CommentThreadPopover
                      scriptData={script}
                      updateScriptApi={updateScriptApi}
                      editorOffsets={editorOffsets}
                      threadID={activeCommentThreadID}
                      selection={selection ?? previousSelection}
                      selectedText={selectedText}
                    />
                  ) : null}
                  <Editable
                    renderElement={renderElement}
                    onPaste={handleOnPaste}
                    renderLeaf={renderLeaf}
                    placeholder="Start Typing"
                    renderPlaceholder={({ children, attributes }) => <p {...attributes}>{children}</p>}
                    spellCheck
                    autoFocus
                    className="printable-div"
                    onKeyDown={(event) => {
                      onKeyDown(event);
                      onKeyDownEditor(event);
                    }}
                    onClick={() => {
                      if (dropD || intEXt) {
                        setIntEXt(null);
                        setDropD(false);
                      }
                    }}
                  />

                  {intEXt && (
                    <Portal>
                      <div
                        ref={intExtRef}
                        style={{
                          top: '-9999px',
                          left: '-9999px',
                          position: 'absolute',
                          zIndex: 1,
                          padding: '3px',
                          background: 'white',
                          borderRadius: '4px',
                          boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                        }}
                        data-cy="mentions-portal"
                      >
                        {CHARACTERS.slice(0, 3).map((char, i) => (
                          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                          <div
                            key={i}
                            onClick={() => {
                              Transforms.select(editor, target);
                              insertMention(editor, char);
                              setIntEXt(null);
                            }}
                            style={{
                              padding: '1px 3px',
                              borderRadius: '3px',
                              background: i === index ? '#B4D5FF' : 'transparent',
                            }}
                          >
                            {char}
                          </div>
                        ))}
                      </div>
                    </Portal>
                  )}
                  {target && chars.length > 0 && (
                    <Portal>
                      <div
                        ref={mentionRef}
                        style={{
                          top: '-9999px',
                          left: '-9999px',
                          position: 'absolute',
                          zIndex: 1,
                          padding: '3px',
                          background: 'white',
                          borderRadius: '4px',
                          boxShadow: '0 1px 5px rgba(0,0,0,.2)',
                        }}
                        data-cy="mentions-portal"
                      >
                        {chars.map((char, i) => (
                          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                          <div
                            key={i}
                            onClick={() => {
                              Transforms.select(editor, target);
                              insertMention(editor, char);
                              setTarget(null);
                            }}
                            style={{
                              padding: '1px 3px',
                              borderRadius: '3px',
                              background: i === index ? '#B4D5FF' : 'transparent',
                            }}
                          >
                            {char}
                          </div>
                        ))}
                      </div>
                    </Portal>
                  )}
                </div>
              </Slate>
            )}
          </Col>
        )}
      </Row>

      
      {
        showAutGenerate && <AutoGenerate 
      onClick={handleAutoGenerateClick}
                sx={{ backgroundColor: '#fe6d29', color: '#fff', '&:hover': { backgroundColor: '#e55d1a' } }}>Auto-Generate</AutoGenerate>}
     
     <MoodGatheringModal
        open={showModal}
        onClose={() => setShowModal(false)}
        script={script}
        updateScriptApi={updateScriptApi}
        editor={editor}
        Transforms={Transforms}
      />
      <ChatBot
        script={script}
        screenplayContent={currentScriptContent}
        updateScriptApi={updateScriptApi}
        editor={editor}
        Transforms={Transforms}
      />
    </>
  );
}

export default ScriptEditors;
