import React, { useState, useRef, useEffect } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { RiCopilotFill } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 800,
  minHeight: 600,
  maxHeight: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '20px',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column'
};

const apiKey = process.env.REACT_APP_API_kEY || '';

const MoodGatheringModal = ({ open, onClose, script, updateScriptApi }) => {
  const [userMessage, setUserMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const chatboxRef = useRef(null);
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (open && messages.length === 0) {
      // Initial question
      handleInitialQuestion();
    }
  }, [open]);

  useEffect(() => {
    if (script?.userMood && script.userMood.length > 0) {
      setMessages(script.userMood);
    }
  }, [script]);

  useEffect(() => {
    if (chatboxRef.current) {
      setTimeout(() => {
        chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
      }, 100);
    }
  }, [messages]);

  const handleInitialQuestion = async () => {
    const initialMessage = {
      text: "Hi! I'm here to understand your screenplay better. Could you tell me what genre or type of story you're planning to write?",
      type: 'incoming'
    };
    
    setMessages([initialMessage]);
    updateScriptApi({ userMood: [initialMessage] });
  };

  const handleMessageChange = (e) => {
    setUserMessage(e.target.value);
  };

  const handleSendMessage = async () => {
    if (!userMessage.trim()) return;

    const newMessage = { text: userMessage, type: 'outgoing' };
    const updatedMessages = [...messages, newMessage];
    
    setMessages(updatedMessages);
    setUserMessage('');
    
    // Add thinking message
    const withThinking = [...updatedMessages, { text: 'Thinking...', type: 'incoming' }];
    setMessages(withThinking);
    
    // Ensure scroll after messages update
    if (chatboxRef.current) {
      setTimeout(() => {
        chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
      }, 100);
    }

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content: `You are a screenplay writing assistant conducting an interview to understand the user's screenplay intent. 
              Ask relevant follow-up questions based on their responses. Focus on understanding these 3 key areas:
              1. Genre and tone of the story
              2. Main plot points and central conflict
              3. Main characters and their key relationships/motivations
              4. 
              
              After 20-30 questions or when you have sufficient understanding, provide a concise summary of the screenplay concept 
              and conclude the conversation. Make the summary actionable for future writing assistance.
              
              Current conversation history: ${JSON.stringify(messages)}`
            },
            { role: 'user', content: userMessage }
          ]
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`
          }
        }
      );

      const botResponse = response.data.choices[0].message.content;
      
      const finalMessages = [
        ...updatedMessages,
        { text: botResponse, type: 'incoming' }
      ];

      setMessages(finalMessages);
      updateScriptApi({ userMood: finalMessages });

      // Ensure scroll after bot response
      if (chatboxRef.current) {
        setTimeout(() => {
          chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
        }, 100);
      }

      // Check if we should conclude the conversation
      if (finalMessages.length >= 20 || botResponse.includes("Thank you for sharing")) {
        setIsComplete(true);
      }

    } catch (error) {
      console.error('Error:', error);
      setMessages(prevMessages => [
        ...prevMessages.slice(0, -1),
        { text: 'Sorry, there was an error. Please try again.', type: 'incoming' }
      ]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Modal open={open} onClose={isComplete ? onClose : undefined}>
      <Box sx={style}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6" component="h2" sx={{ color: '#fe6d29' }}>
            Let's Understand Your Screenplay
          </Typography>
          <IoClose 
            size={24} 
            color="#333"
            style={{ 
              cursor: 'pointer',
              transition: 'color 0.2s',
            }}
            onMouseOver={(e) => e.target.style.color = '#fe6d29'}
            onMouseOut={(e) => e.target.style.color = '#333'}
            onClick={onClose}
          />
        </Box>
        
        <Box 
          sx={{ 
            flexGrow: 1, 
            mb: 2,
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 300px)',
          }} 
          ref={chatboxRef}
        >
          <ul className="chatbox" style={{ listStyle: 'none', padding: 0 }}>
            {messages.map((message, index) => (
              <li key={index} className={`chat ${message.type}`}>
                {message.type === 'outgoing' ? (
                  <p>{message.text}</p>
                ) : (
                  <>
                    <span>
                      <RiCopilotFill size={20} color="#fff" style={{ marginTop: '5px' }} />
                    </span>
                    <p className='message-response'>{message.text}</p>
                  </>
                )}
              </li>
            ))}
          </ul>
        </Box>

        <Box sx={{ display: 'flex', gap: 1, mt: 'auto' }}>
          <textarea
            value={userMessage}
            onChange={handleMessageChange}
            onKeyDown={handleKeyDown}
            placeholder="Type your response..."
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '4px',
              border: '1px solid #ddd',
              resize: 'none',
              height: '100px'
            }}
          />
          <button
            onClick={handleSendMessage}
            style={{
              padding: '10px 20px',
              backgroundColor: '#fe6d29',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              height: 'fit-content',
              alignSelf: 'flex-end'
            }}
          >
            Send
          </button>
        </Box>
      </Box>
    </Modal>
  );
};

export default MoodGatheringModal; 