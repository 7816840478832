/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Tab,
  Tabs,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Chip,
  Container,
  Button,
  CircularProgress,
  Modal,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import questions from '../../utils/questions-consts.json';
import QuestionnaireSetupModal from './QuestionnaireSetupModal';
import NewScriptPoper from 'src/components/desk/Sidebar/dashboard/NewScriptPoper';
import { useFetch } from 'src/hooks/useFetch';
import { getCurrentUser } from 'src/utils/helpers';

// Custom styled components
const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#fe6d29',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#fe6d29',
    },
    '&:hover fieldset': {
      borderColor: '#fe6d29',
    },
  },
});

const StyledSelect = styled(Select)({
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#fe6d29',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#fe6d29',
  },
});

const StyledInputLabel = styled(InputLabel)({
  '&.Mui-focused': {
    color: '#fe6d29',
  },
});

const SubmitButton = styled(Button)({
  backgroundColor: '#fe6d29',
  color: 'white',
  padding: '10px 30px',
  '&:hover': {
    backgroundColor: '#e55d1a',
  },
  marginTop: '2rem',
  marginBottom: '3rem',
});

const SuggestButton = styled(Button)({
  backgroundColor: 'transparent',
  color: '#fe6d29',
  border: '1px solid #fe6d29',
  padding: '5px 15px',
  fontSize: '0.875rem',
  marginTop: '8px',
  '&:hover': {
    backgroundColor: 'rgba(254, 109, 41, 0.1)',
  },
});

const NextButton = styled(Button)({
  backgroundColor: '#fe6d29',
  color: 'white',
  marginTop: '10px',
  '&:hover': {
    backgroundColor: '#e55d1a',
  },
  '&:disabled': {
    backgroundColor: '#ccc',
  },
});

const QuestionInput = ({
  question,
  value,
  onChange,
  isAIFilling,
  isProcessing,
  handleNextQuestion,
  isCurrentQuestion,
}) => {
  const [isSuggesting, setIsSuggesting] = useState(false);
  const [suggestCount, setSuggestCount] = useState(0);

  const handleSuggest = async () => {
    setIsSuggesting(true);
    const prompt = suggestCount === 0
      ? `Generate a realistic ${question.type === 'textarea' ? 'detailed' : 'brief'} value for a character's ${question.label}. Only provide the actual value, no explanations. For example, if it's a birth chart, provide something like "Sun in Leo, Moon in Pisces, Rising in Virgo".`
      : `Generate a different ${question.type === 'textarea' ? 'detailed' : 'brief'} value for a character's ${question.label}. Only provide the actual value, no explanations.`;

    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_API_kEY}`,
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content: 'You are a creative writing assistant. Provide only the actual values that should be filled in the form field. No explanations or descriptions - just the value itself.',
            },
            { role: 'user', content: prompt },
          ],
        }),
      });

      const data = await response.json();
      let content = data?.choices?.[0]?.message?.content || '';
      
      // Remove any explanations or colons that might be in the response
      content = content.replace(/^(Here's|This is|The|A|An) /i, '');
      content = content.replace(/:/g, '');
      content = content.replace(/^"(.*)"$/, '$1');
      
      onChange(question.id, content);
      setSuggestCount((prev) => prev + 1);
    } catch (error) {
      console.error('Error getting suggestion:', error);
    } finally {
      setIsSuggesting(false);
    }
  };

  const handleChipInput = (e) => {
    const inputValue = e.target.value;
    const lastChar = inputValue[inputValue.length - 1];
    if (lastChar === ',') {
      const chips = inputValue
        .split(',')
        .map((chip) => chip.trim())
        .filter((chip) => chip.length > 0);
      const existingChips = Array.isArray(value) ? value : [];
      const newChips = [...existingChips, ...chips];
      onChange(question.id, newChips);
      e.target.value = '';
    }
  };

  const handleChipDelete = (chipToDelete) => {
    const newValue = Array.isArray(value) ? value.filter((chip) => chip !== chipToDelete) : [];
    onChange(question.id, newValue);
  };

  const renderSuggestButton = () => (
    <SuggestButton
      onClick={handleSuggest}
      disabled={isSuggesting}
      startIcon={isSuggesting ? <CircularProgress size={16} color="inherit" /> : null}
    >
      {suggestCount === 0 ? 'Suggest' : 'Suggest Again'}
    </SuggestButton>
  );

  return (
    <Box>
      <StyledTextField
        fullWidth
        label={question.label}
        value={value || ''}
        onChange={(e) => onChange(question.id, e.target.value)}
        required={question.required}
        multiline={question.type === 'textarea'}
        rows={question.type === 'textarea' ? 4 : 1}
        margin="normal"
      />
      <SuggestButton
        onClick={handleSuggest}
        disabled={isSuggesting}
        startIcon={isSuggesting ? <CircularProgress size={16} color="inherit" /> : null}
      >
        {suggestCount === 0 ? 'Suggest' : 'Suggest Again'}
      </SuggestButton>
    </Box>
  );
};

const QuestionSection = ({
  section,
  values,
  onChange,
  isAIFilling,
  isProcessing,
  handleNextQuestion,
  currentQuestionIndex,
  allQuestions,
}) => (
  <Box sx={{ mb: 4 }}>
    <Typography variant="h6" sx={{ mb: 2, color: '#fe6d29' }}>
      {section.title}
    </Typography>
    {section.questions.map((question, index) => (
      <Box key={question.id}>
        <QuestionInput
          question={question}
          value={values[question.id]}
          onChange={onChange}
          isAIFilling={isAIFilling}
          isProcessing={isProcessing && currentQuestionIndex === index}
          handleNextQuestion={handleNextQuestion}
          isCurrentQuestion={currentQuestionIndex === index}
        />
      </Box>
    ))}
  </Box>
);

const Questionnaire = () => {
  const [progress, setProgress] = useState(0);
  const [tab, setTab] = useState(0);
  const [values, setValues] = useState({}); // Store values for all characters
  const [setupModalOpen, setSetupModalOpen] = useState(true);
  const [isAIFilling, setIsAIFilling] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [allQuestions, setAllQuestions] = useState([]);
  const [characterName, setCharacterName] = useState('');
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [processedCharacters, setProcessedCharacters] = useState(new Set()); // Track processed characters
  const [generalInfo, setGeneralInfo] = useState({});
  const user = getCurrentUser();
  const [archive, setArchive] = React.useState(false);

  
  
  const [scripts, setScripts] = React.useState([]);
  React.useEffect(() => {
    fetchScripts();
  }, []);
  const fetchScripts = async () => {
    const response = await getScripts();
    if (response?.data?.scripts) {
      setScripts(response?.data?.scripts);
      setPagination({
        totalPages: response?.data?.totalPages,
        currentPage: response?.data?.currentPage,
        totalRecords: response?.data?.totalRecords,
      });
    }
  };
  const [pagination, setPagination] = React.useState({
    totalPages: 0,
    currentPage: 1,
    totalRecords: 0,
  });

  const [getScripts, loading] = useFetch(
    `api/scripts?pageSize=50&page=${pagination.currentPage}&ownerId=${user?.id}&archieved=${archive}`,
    ``,
    '',
    'GET',
    false
  );

  const handleSaveData = () => {
    // Save AI-generated data to local storage
    localStorage.setItem('charactersData', JSON.stringify({ values }));
    localStorage.setItem('generalInfo', JSON.stringify({ generalInfo }));
    window.location = `/script-dummy/677edfb15390dd6528bafc43`; // Navigate after saving data
  };


  const handleChange = (questionId, value) => {
    setValues((prev) => ({
      ...prev,
      [characterName]: {
        ...prev[characterName],
        [questionId]: value,
      },
    }));
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleSubmit = () => {
    console.log('Form Values:', values);
  };

  const processCurrentQuestion = async () => {
    if (currentQuestionIndex >= allQuestions.length || !isAIFilling) {
      setIsAIFilling(false);
      setSetupModalOpen(false);
      setProcessedCharacters((prev) => new Set([...prev, characterName])); // Mark as processed
      return;
    }

    setIsProcessing(true);
    const question = allQuestions[currentQuestionIndex];
    console.log('❓ Processing Question:', question.label);

    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_API_kEY}`,
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content: `You are helping to create a character profile for "${characterName}". Provide concise, creative answers.`,
            },
            {
              role: 'user',
              content: `Generate a ${question.type === 'chips' ? 'comma-separated list' : 'brief answer'} for: ${
                question.label
              }`,
            },
          ],
        }),
      });

      if (!response.ok) {
        throw new Error(`API request failed: ${response.status}`);
      }

      const data = await response.json();
      const content = data?.choices?.[0]?.message?.content || '';

      setValues((prev) => ({
        ...prev,
        [characterName]: {
          ...prev[characterName],
          [question.id]: question.type === 'chips' ? content.split(',').map((item) => item.trim()) : content,
        },
      }));

      const progress = ((currentQuestionIndex + 1) / allQuestions.length) * 100;
      setProgress(Math.min(Math.round(progress), 100));
    } catch (error) {
      console.error('🚨 Error processing question:', question.label, error);
    } finally {
      setIsProcessing(false);
      setCurrentQuestionIndex((prev) => prev + 1);
    }
  };

  const handleAIFilling = async (name, level, setProgress, setCurrentQuestion, state) => {
    setGeneralInfo(state);
    setCharacterName(name);
    setIsAIFilling(true);
    const questionsList = level === 'basic' ? questions.basic : questions.advanced;
    setTab(level === 'basic' ? 0 : 1);
    const allQuestions = Object.values(questionsList).flatMap((section) => section.questions);
    setAllQuestions(allQuestions);
    setCurrentQuestionIndex(0);

    const characterData = {
      characterName: name,
      answers: {},
    };

    for (let i = 0; i < allQuestions.length; i += 1) {
      setCurrentQuestion(allQuestions[i].label); // Update current question
      setProgress(((i + 1) / allQuestions.length) * 100); // Update progress

      // Store the actual answer from the `values` state
      const answer = values[characterName]?.[allQuestions[i].id];
      characterData.answers[allQuestions[i].id] = answer;

      // Simulate API call or processing delay
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  };

  useEffect(() => {
    if (isAIFilling && allQuestions.length > 0) {
      processCurrentQuestion();
    }
  }, [currentQuestionIndex, allQuestions, isAIFilling]);

  return (
    <>
      <QuestionnaireSetupModal
        open={setupModalOpen}
        onClose={() => setSetupModalOpen(false)}
        onStartFilling={handleAIFilling}
        progress={progress}
      />
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="h4" sx={{ mb: 4, color: '#fe6d29', textAlign: 'center' }}>
          Character Questionnaire
        </Typography>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            centered
            sx={{
              '& .MuiTab-root.Mui-selected': {
                color: '#fe6d29',
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#fe6d29',
              },
            }}
          >
            <Tab label="Basic Information" />
            <Tab label="Advanced Details" />
          </Tabs>
        </Box>

        {tab === 0 && (
          <Box component="form">
            {Object.entries(questions.basic).map(([key, section]) => (
              <QuestionSection
                key={key}
                section={section}
                values={values[characterName] || {}}
                onChange={handleChange}
                isAIFilling={isAIFilling}
                isProcessing={isProcessing}
                handleNextQuestion={() => setCurrentQuestionIndex((prev) => prev + 1)}
                currentQuestionIndex={currentQuestionIndex}
                allQuestions={allQuestions}
              />
            ))}

            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
              <SubmitButton onClick={handleSubmit}>Save Basic Information</SubmitButton>
              {/* <SubmitButton
                onClick={() => setShowDetailsModal(true)}
                sx={{ backgroundColor: '#fe6d29', color: 'white', '&:hover': { backgroundColor: '#e55d1a' } }}
              >
                Show Co-worker Data
              </SubmitButton> */}
                  <Box >
                    <SubmitButton
                      sx={{
                        backgroundColor: '#fe6d29',
                        color: 'white',
                        '&:hover': { backgroundColor: '#e55d1a' },
                      }}
                      onClick={() => {
                        handleSaveData(); // Call first function
                       
                      }}
                    >
                      Continue Screnpla
                    </SubmitButton>
                  </Box>
            </Box>
          </Box>
        )}
        <Modal
          open={showDetailsModal}
          onClose={() => setShowDetailsModal(false)}
          aria-labelledby="ai-generated-data-modal"
          aria-describedby="ai-generated-data-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              maxWidth: '600px',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" id="ai-generated-data-modal" sx={{ mb: 2 }}>
              Co-worker Generated Data for All Characters
            </Typography>
            <Box
              component="pre"
              sx={{
                maxHeight: '400px',
                overflow: 'auto',
                bgcolor: '#f5f5f5',
                p: 2,
                borderRadius: 1,
              }}
            >
              {JSON.stringify(values, null, 2)}
            </Box>
            <Button
              onClick={() => setShowDetailsModal(false)}
              sx={{ mt: 2, backgroundColor: '#fe6d29', color: 'white', '&:hover': { backgroundColor: '#e55d1a' } }}
            >
              Close
            </Button>
            <Button onClick={handleSaveData}>Save Data</Button>
          </Box>
        </Modal>
        {tab === 1 && (
          <Box component="form">
            {Object.entries(questions.advanced).map(([key, section]) => (
              <QuestionSection
                key={key}
                section={section}
                values={values[characterName] || {}}
                onChange={handleChange}
                isAIFilling={isAIFilling}
                isProcessing={isProcessing}
                handleNextQuestion={() => setCurrentQuestionIndex((prev) => prev + 1)}
                currentQuestionIndex={currentQuestionIndex}
                allQuestions={allQuestions}
              />
            ))}
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
              <SubmitButton onClick={handleSubmit}>Save Advanced Information</SubmitButton>

              <SubmitButton
                onClick={() => setShowDetailsModal(true)}
                sx={{ backgroundColor: '#fe6d29', color: 'white', '&:hover': { backgroundColor: '#e55d1a' } }}
              >
                Show Co-worker Data
              </SubmitButton>
              <SubmitButton
                sx={{ backgroundColor: '#fe6d29', color: 'white', '&:hover': { backgroundColor: '#e55d1a' } }}
              >
                Continue Screnpla
              </SubmitButton>
            </Box>
          </Box>
        )}
      </Container>
    </>
  );
};

export default Questionnaire;
