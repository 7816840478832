import React, { useState, useRef } from 'react';
import {
  Box,
  Modal,
  Typography,
  Button,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import NewScriptPoper from 'src/components/desk/Sidebar/dashboard/NewScriptPoper';
import { useFetch } from 'src/hooks/useFetch';
import { getCurrentUser } from 'src/utils/helpers';
import { convertFDXContent, convertPDFToSlateJS, readFileContent } from 'src/utils/importUtils';
import { useSnackbar } from 'notistack';
import { getFileExtension, getFileNameWithoutExtension } from 'src/utils/common';
import { Document, Page, pdfjs } from 'react-pdf';
import { gettingStarted, initialValue } from 'src/utils/constants';




const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ModalContent = styled(Box)({
  backgroundColor: 'white',
  padding: '2rem',
  borderRadius: '8px',
  width: '90%',
  maxWidth: '500px',
  position: 'relative',
});

const StyledButton = styled(Button)({
  backgroundColor: '#fe6d29',
  color: 'white',
  '&:hover': {
    backgroundColor: '#e55d1a',
  },
});

const steps = {
  CHOICE: 'CHOICE',
  TITLE:'TITLE',
  NAME: 'NAME',
  CONFIRM: 'CONFIRM',
  LEVEL: 'LEVEL',
  PROCESSING: 'PROCESSING',
};

const QuestionnaireSetupModal = ({ open, onClose, onStartFilling }) => {
  const [step, setStep] = useState(steps.CHOICE);
  const [fillMethod, setFillMethod] = useState('manual');
  const [characterNames, setCharacterNames] = useState('');
  const [level, setLevel] = useState('basic');
  const [progress, setProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentCharacterIndex, setCurrentCharacterIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState('');

// *********** copy from NewScriptPoper ***********
  const user = getCurrentUser();
  const [archive, setArchive] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [defaultScript, setDefaultScript] = React.useState(null);
  const drawerRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [fileContent, setFileContent] = useState(null);
  const [scripts, setScripts] = React.useState([]);
  React.useEffect(() => {
    fetchScripts();
  }, []);
  const fetchScripts = async () => {
    const response = await getScripts();
    if (response?.data?.scripts) {
      setScripts(response?.data?.scripts);
      setPagination({
        totalPages: response?.data?.totalPages,
        currentPage: response?.data?.currentPage,
        totalRecords: response?.data?.totalRecords,
      });
    }
  };

  const handleSaveData = () => {
    setState((prevState) => {
      const dataToSave = {
        ...prevState,  // Ensure all state data is included
        characterNames,
      };
  
      // Save to local storage (temporary)
      localStorage.setItem('questionnaireData', JSON.stringify(dataToSave));
  
      // Alternatively, send the data to a backend API
      fetch('/api/saveQuestionnaireData', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSave),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('Data saved successfully:', data);
        })
        .catch((error) => {
          console.error('Error saving data:', error);
        });
  
      return prevState; // Ensure state remains unchanged in this function
    });
  };
  


 const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setState({
        title: file.name.split('.')[0],
        subTitle: '',
        writtenBy: user?.name,
        genre: '',
        pageTarget: 0,
        logline: '',
        ownerId: user?.id,
      });
      const fileExtension = file.name.split('.').pop().toLowerCase();

      // Check if the file extension is allowed
      if (['fdx', 'pdf', 'scrn'].includes(fileExtension)) {
        const fileContent = await readFileContent(file);
        // Assuming .scrn contains JSON string, parse it
        if (fileExtension === 'scrn') {
          try {
            const jsonData = JSON.parse(fileContent);
            setState((prev) => ({ ...prev, content: jsonData }));
          } catch (error) {
            enqueueSnackbar(`Error parsing JSON: ${error}`, {
              variant: 'error',
            });
          }
        } 
        if (fileExtension === 'fdx') {
          const convertedContent = convertFDXContent(fileContent);
          setState({content: convertedContent });
        }
     
        if (fileExtension === 'pdf') {
          // Handle pdf file format here
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = async (e) => {
            const convertedContent = e.target.result;           
            setFileContent(convertedContent);
           };
        }
      } else {
        enqueueSnackbar(`Invalid file format. Allowed formats: .fdx, .docx, .pdf, .scrn`, {
          variant: 'error',
        });    
      }
    }
  };
const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [pagination, setPagination] = React.useState({
    totalPages: 0,
    currentPage: 1,
    totalRecords: 0,
  });
const [pdfContent, setPdfContent] = useState([
    ...gettingStarted,
  ]);
  const handlePageLoadSuccess = async (page) => {
    try {
      const text = await page.getTextContent();
      // eslint-disable-next-line no-useless-escape
      const pageText = text.items.map((item) => item.str).join('').replace(/(\.|\?|\!|\:|\;|\n)/g, '$1\n');
      setTimeout(() => {
        const convertedContent = convertPDFToSlateJS(pageText);
        if (convertedContent.length > 0) {
          setPdfContent((prevContent) => [...prevContent, ...convertedContent]);
      }
       
      }, 1000);
    } catch (error) {
      // Handle any errors that occur during the asynchronous operations
      console.error('Error loading text content:', error);
    }
  }

  const [getScripts, loading] = useFetch(
    `api/scripts?pageSize=50&page=${pagination.currentPage}&ownerId=${user?.id}&archieved=${archive}`,
    ``,
    '',
    'GET',
    false
  );

const [state, setState] = useState({
    title: '',
    description: '',
    genre: '',
    pageTarget: 0,
    logline: '',
  });

  const handleStart = async () => {
    if (fillMethod === 'manual') {
      onClose();
      return;
    }

    // Set step to PROCESSING and isProcessing to true
    setStep(steps.PROCESSING);
    setIsProcessing(true);
    setProgress(0);

    const names = characterNames.split(',').map((name) => name.trim());

    // Disable ESLint rule for this loop
    // eslint-disable-next-line no-await-in-loop
    for (let index = 0; index < names.length; index += 1) {
      setCurrentCharacterIndex(index);
      // eslint-disable-next-line no-await-in-loop
      await onStartFilling(names[index], level, setProgress, setCurrentQuestion, state);
    }

    // Reset states after processing is complete
    setIsProcessing(false);
    onClose();
  };

  const renderStep = () => {
    switch (step) {
      case steps.CHOICE:
        return (
          <>
            <Typography variant="h6" sx={{ mb: 3 }}>
              How would you like to fill out the questionnaire?
            </Typography>
            <RadioGroup value={fillMethod} onChange={(e) => setFillMethod(e.target.value)}>
              <FormControlLabel value="manual" control={<Radio />} label="I'll fill it out manually" />
              <FormControlLabel value="ai" control={<Radio />} label="Let Co-Writer help me fill it out" />
            </RadioGroup>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <StyledButton
                onClick={() => {
                  if (fillMethod === 'manual') {
                    onClose();
                  } else {
                    setStep(steps.TITLE);
                  }
                }}
              >
                Next
              </StyledButton>
            </Box>
          </>
        );
        case steps.TITLE:
          return(
            <>
             
                      <Typography sx={{ fontSize: '1.25rem', fontFamily: 'inter' }}>
                        General Information
                      </Typography>
            
                      {/* <Box mt={'20px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box display={'flex'} alignItems={'center'} gap={'15px'}>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            sx={{ width: '35px', height: '35px', backgroundColor: '#fe6d29', borderRadius: '50%' }}
                          >
                            <Typography sx={{ color: '#fff', fontFamily: 'inter' }}>1</Typography>
                          </Box>
                          <Box>
                            <Typography sx={{ fontSize: '1rem', fontFamily: 'inter' }}>Import a script?</Typography>
                            <Typography sx={{ fontSize: '.75rem', color: '#b2b3c4' }}>fdx / foundation / pdf / word / celtx / movie magic</Typography>
                          </Box>
                        </Box>
                        <Box >
                           
                           
                              <Typography p={'0.5rem 2rem'} sx={{ cursor: 'pointer', backgroundColor: '#00017806', fontSize: '.875rem', fontFamily: 'inter' }} >Import</Typography>
                            
                            <input
                              id="file-input"
                              type="file"
                              accept=".fdx, .docx, .pdf, .scrn"
                              style={{ display: 'none' }}
                              onChange={handleFileChange}
                            />
                        </Box>
                      </Box> */}
                      {/* {
                        selectedFile &&
                        <Box mt={'20px'} display={'flex'} 
                          sx={{
                            backgroundColor: 'orange',
                            padding: '10px',
                            borderRadius: '5px',
                          }}
                        alignItems={'center'} justifyContent={'space-between'}>
                          <Box>
                            <Typography sx={{ fontSize: '.875rem', fontWeight: 400, fontFamily: 'inter' }}>
                              You are about to import <b style={{color: 'white'}}>{getFileNameWithoutExtension(selectedFile?.name)}</b> in <b style={{color: 'white', textTransform: 'uppercase'}}>{getFileExtension(selectedFile?.name)}</b> format. You might need to format the content after import.
                              Because the content might not be formatted correctly.
                            </Typography>
                          </Box>
                          
                        </Box>
                      }
                     {fileContent &&  
                     <div style={{display: 'none'}}>
                      <Document
                        file={fileContent}
                        onLoadSuccess={() => onDocumentLoadSuccess}
                        onLoadError={console.error}
                        className="pdf-document"
                      >
                        {[...Array(numPages).keys()].map((pageIndex) => (
                      <Page
                        key={`page_${pageIndex + 1}`}
                        pageNumber={pageIndex + 1}
                        onLoadSuccess={handlePageLoadSuccess}
                      />
                    ))}
                      </Document>
                    </div>} */}
                      {/* <Box mt={'20px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box display={'flex'} alignItems={'center'} gap={'15px'}>
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'center'}
                            sx={{ width: '35px', height: '35px', backgroundColor: '#fe6d29', borderRadius: '50%' }}
                          >
                            <Typography sx={{ color: '#fff', fontFamily: 'inter' }}>2</Typography>
                          </Box>
                          <Box>
                            <Typography sx={{ fontSize: '1rem', fontFamily: 'inter' }}>Additional information</Typography>
                            <Typography sx={{ fontSize: '.75rem', color: '#b2b3c4' }}>
                              You can always change this later, feel free to skip any fields
                            </Typography>
                          </Box>
                        </Box>
                        
                      </Box> */}
            
                      <Box >
                        <Box mt={'10px'}>
                          <Typography sx={{ fontSize: '.875rem' }}>Title</Typography>
                          <input
                            className="dash-scr-input light-bg dark-text"
                            value={state?.title}
                            onChange={(e) => setState({ ...state, title: e.target.value })}
                          />
                        </Box>
            
                        <Box mt={'10px'}>
                          <Typography sx={{ fontSize: '.875rem' }}>Description</Typography>
                          <textarea
                            className="dash-scr-input light-bg dark-text"
                            value={state?.description}
                            rows={4}
                            onChange={(e) => setState({ ...state, description: e.target.value })}
                          />
                        </Box>

                        {/* <Box
                          mt={'10px'}
                          display={'flex'}
                          alignItems={'center'}
                          gap={'10px'}
                          //    justifyContent={'space-between'}
                        >
                          <Box width={'100%'}>
                            <Typography sx={{ fontSize: '.875rem' }}>Subtitle</Typography>
                            <input
                              className="dash-scr-input light-bg dark-text"
                              value={state?.subTitle}
                              onChange={(e) => setState({ ...state, subTitle: e.target.value })}
                            />
                          </Box>
                          <Box width={'100%'}>
                            <Typography sx={{ fontSize: '.875rem' }}>Written by</Typography>
                            <input
                              className="dash-scr-input light-bg dark-text"
                              placeholder="Jim Bulian"
                              value={state?.writtenBy || user?.name}
                              onChange={(e) => setState({ ...state, writtenBy: e.target.value })}
                            />
                          </Box>
                        </Box> */}
            
                        <Box mt={'10px'} display={'flex'} alignItems={'center'} gap={'10px'}>
                          <Box width={'70%'}>
                            <Typography sx={{ fontSize: '.875rem' }}>Genre</Typography>
                            <input
                              className="dash-scr-input light-bg dark-text"
                              value={state?.genre}
                              onChange={(e) => setState({ ...state, genre: e.target.value })}
                            />
                          </Box>
                          <Box width={'30%'}>
                            <Typography sx={{ fontSize: '.875rem' }}>Page Target</Typography>
                            <input
                              className="dash-scr-input light-bg dark-text"
                              placeholder="110"
                              type="number"
                              value={state?.pageTarget}
                              onChange={(e) => setState({ ...state, pageTarget: e.target.value })}
                            />
                          </Box>
                        </Box>
            
                        <Box mt={'10px'}>
                          <Typography sx={{ fontSize: '.875rem' }}>Logline</Typography>
                          <input
                            className="dash-scr-input light-bg dark-text"
                            value={state?.logline}
                            onChange={(e) => setState({ ...state, logline: e.target.value })}
                          />
                        </Box>
                      </Box>
                      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <StyledButton
                onClick={() => {
                  handleSaveData();
                  if (fillMethod === 'manual') {
                    onClose();
                  } else {
                    setStep(steps.NAME);
                  }
                }}
              >
                Next
              </StyledButton>
            </Box>
                      
                   
            </>
          )
      case steps.NAME:
        return (
          <>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Enter your character names (separated by commas)
            </Typography>
            <TextField
              fullWidth
              value={characterNames}
              onChange={(e) => setCharacterNames(e.target.value)}
              placeholder="e.g., John, Jane, Doe"
              sx={{ mb: 3 }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={() => setStep(steps.CHOICE)}>Back</Button>
              <StyledButton
  onClick={async () => {
    await handleSaveData(); // Ensure data is saved before proceeding
    setStep(steps.CONFIRM);
  }}
  disabled={!characterNames.trim()}
>
  Next
</StyledButton>

            </Box>
          </>
        );


      case steps.CONFIRM:
        return (
          <>
            <Typography variant="h6" sx={{ mb: 3 }}>
              You want all characters to be filled out by Co-Writer?
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={() => setStep(steps.NAME)}>Back</Button>
              <StyledButton onClick={() => setStep(steps.LEVEL)}>Yes, Continue</StyledButton>
            </Box>
          </>
        );

      case steps.LEVEL:
        return (
          <>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Which level would you like to fill?
            </Typography>
            <RadioGroup value={level} onChange={(e) => setLevel(e.target.value)}>
              <FormControlLabel value="basic" control={<Radio />} label="Basic Information" />
              <FormControlLabel value="advanced" control={<Radio />} label="Advanced Details" />
            </RadioGroup>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={() => setStep(steps.CONFIRM)}>Back</Button>
              <StyledButton onClick={handleStart}>Start Filling</StyledButton>
            </Box>
          </>
        );

      case steps.PROCESSING:
        console.log('Rendering PROCESSING step');
        console.log('Current Character:', characterNames.split(',')[currentCharacterIndex]);
        console.log('Current Question:', currentQuestion);
        console.log('Progress:', progress);

        return (
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Co-Writer is filling out the questionnaire...
            </Typography>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
              <CircularProgress variant="determinate" value={progress} size={60} sx={{ color: '#fe6d29' }} />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="caption" component="div" color="text.secondary">
                  {`${Math.round(progress)}%`}
                </Typography>
              </Box>
            </Box>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Character: {characterNames.split(',')[currentCharacterIndex]}
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              typing... {currentQuestion}
            </Typography>
            <Button
              onClick={() => {
                setIsProcessing(false);
                onClose();
              }}
              sx={{ mt: 3, display: 'block', mx: 'auto' }}
            >
              Stop
            </Button>
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <StyledModal open={open} onClose={isProcessing ? undefined : onClose}>
      
      <ModalContent>
        
      {renderStep()}</ModalContent>
    </StyledModal>
  );
};

export default QuestionnaireSetupModal;
