/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import { useFetch } from 'src/hooks/useFetch';
import { SERVER_BASE_URL } from 'src/config';

import { Box, CircularProgress, Drawer, Typography } from '@mui/material';
import './NewScriptPoper.css';
import { useLocation, useNavigate } from 'react-router';
import { isEmpty } from 'src/utils/helpers';
import { DESK, ROOT_SCRIPTS,} from '../../../../routes/paths';
import { convertFDXContent, convertPDFToSlateJS, readFileContent } from 'src/utils/importUtils';
import { getFileExtension, getFileNameWithoutExtension } from 'src/utils/common';

import { Document, Page, pdfjs } from 'react-pdf';
import useDebounce from 'src/hooks/useDebounce';
import { gettingStarted, initialValue } from 'src/utils/constants';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const NewScriptPoper = ({ refreshData, defaultScript, setDefaultScript }, ref) => {
  // const { user } = useAuth();
  const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    title: '',
    subTitle: '',
    writtenBy: '',
    genre: '',
    pageTarget: 0,
    logline: '',
    content: '',
    ownerId: user?.id,
  });

  const [updateLoading, setUpdateLoading] = useState(false);
  const addScripEndpoint = 'api/scripts';

  const [createScript, loading] = useFetch(addScripEndpoint, ``, '', 'POST', false);

  useImperativeHandle(ref, () => ({
    openDrawer,
    closeDrawer,
  }));

  useEffect(() => {
    if (defaultScript) {
      setState({
        title: defaultScript?.title,
        subTitle: defaultScript?.subTitle,
        writtenBy: defaultScript?.writtenBy,
        genre: defaultScript?.genre,
        pageTarget: defaultScript?.pageTarget,
        logline: defaultScript?.logline,
        ownerId: defaultScript?.ownerId,
      });
    } else {
      setState({
        title: '',
        subTitle: '',
        writtenBy: '',
        genre: '',
        pageTarget: 0,
        logline: '',
        ownerId: user?.id,
      });
    }
  }, [defaultScript]);

  // useEffect(() => {
  //   if (user) {
  //     setState({ ...state, ownerId: user?.id });
  //   }
  // }, [user]);

  // Function to open the Drawer
  const openDrawer = () => {
    setOpen(true);
  };

  // Function to close the Drawer
  const closeDrawer = () => {
    setDefaultScript(null);
    setOpen(false);
  };

  const handleBackdropClick = () => {
    // Close the drawer when clicking on the backdrop
    setDefaultScript(null);
    closeDrawer();
  };
  const location = useLocation();
  // eslint-disable-next-line consistent-return
  const handleScript = async () => {
    if (!validate()) return false;

    try {
      if (defaultScript) {
        setUpdateLoading(true);
        const updateScriptEndpoint = `${SERVER_BASE_URL}/api/scripts/${defaultScript?.id}`;
        const response = await fetch(updateScriptEndpoint, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken') || ''}`,
          },
          body: JSON.stringify({
            title: state?.title,
            subTitle: state?.subTitle,
            writtenBy: state?.writtenBy || user?.name,
            genre: state?.genre,
            pageTarget: parseInt(state?.pageTarget, 10),
            logline: state?.logline,
            ownerId: state?.ownerId,
          }),
        });
        const json = await response?.json();
        if (json?.success) {
          setUpdateLoading(false);
          enqueueSnackbar(`Script updated successfully`, {
            variant: 'success',
          });
          setState({
            title: '',
            subTitle: '',
            writtenBy: '',
            genre: '',
            pageTarget: 0,
            logline: '',
            ownerId: user?.id,
          });
          if(refreshData) refreshData();
          closeDrawer();
        } else {
          setUpdateLoading(false);
          enqueueSnackbar('Somthing went wrong while updating a script', {
            variant: 'error',
          });
        }
      } else {
        if (selectedFile && !state?.content) {
          enqueueSnackbar('Content not found for the imported file, please wait...', {
            variant: 'error',
            });
            // eslint-disable-next-line consistent-return
            return;
        }
        const response = await createScript({
          title: state?.title || getFileNameWithoutExtension(selectedFile?.name) || "",
          subTitle: state?.subTitle || "",
          writtenBy: state?.writtenBy || user?.name,
          content: state?.content?.length > 0 && state?.content[0]?.type === 'getting-started' ? state?.content.slice(1) : state?.content || initialValue,
          genre: state?.genre || "",
          pageTarget: parseInt(state?.pageTarget, 10) || 0,
          logline: state?.logline || "",
          ownerId: state?.ownerId || user?.id,
        });
        if (response?.success) {
          enqueueSnackbar(`Script created successfully`, {
            variant: 'success',
          });
          setState({
            title: '',
            subTitle: '',
            writtenBy: '',
            genre: '',
            pageTarget: 0,
            logline: '',
            ownerId: user?.id,
          });
          if(refreshData) refreshData();
          closeDrawer();
          // if (location && location.pathname === DESK ) {
          //   navigate(ROOT_SCRIPTS);
          // }
          // else {
            // console.log('navigate to script editor', response?.data?.id);
            navigate(`../script/${response?.data?.id}`);
          // }
        }
      }
    } catch (error) {
      enqueueSnackbar('Somthing went wrong while creating a script', {
        variant: 'error',
      });
      setUpdateLoading(false);
    }
  };

  const validate = () => {
    let isValid = true;
    const ignoredKeys = ['pageTarget', 'subTitle', 'writtenBy', 'genre', 'logline', 'content'];
    // eslint-disable-next-line consistent-return
    Object.keys(state)?.forEach((key) => {
      if (isEmpty(state[key]) && !ignoredKeys?.includes(key)) {
        enqueueSnackbar(`${key} is required`, { variant: 'error' });
        isValid = false;
        return false;
      }
    });
    return isValid;
  };
// *********** Files upload/Import ***********
const [fileContent, setFileContent] = useState(null);
const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setState({
        title: file.name.split('.')[0],
        subTitle: '',
        writtenBy: user?.name,
        genre: '',
        pageTarget: 0,
        logline: '',
        ownerId: user?.id,
      });
      const fileExtension = file.name.split('.').pop().toLowerCase();

      // Check if the file extension is allowed
      if (['fdx', 'pdf', 'scrn'].includes(fileExtension)) {
        const fileContent = await readFileContent(file);
        // Assuming .scrn contains JSON string, parse it
        if (fileExtension === 'scrn') {
          try {
            const jsonData = JSON.parse(fileContent);
            setState((prev) => ({ ...prev, content: jsonData }));
          } catch (error) {
            enqueueSnackbar(`Error parsing JSON: ${error}`, {
              variant: 'error',
            });
          }
        } 
        if (fileExtension === 'fdx') {
          const convertedContent = convertFDXContent(fileContent);
          setState({content: convertedContent });
        }
     
        if (fileExtension === 'pdf') {
          // Handle pdf file format here
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = async (e) => {
            const convertedContent = e.target.result;           
            setFileContent(convertedContent);
           };
        }
      } else {
        enqueueSnackbar(`Invalid file format. Allowed formats: .fdx, .docx, .pdf, .scrn`, {
          variant: 'error',
        });    
      }
    }
  };

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const [pdfContent, setPdfContent] = useState([
    ...gettingStarted,
  ]);
  async function onPageLoadSuccess(page) {
    try {
      const text = await page.getTextContent();
      // eslint-disable-next-line no-useless-escape
      const pageText = text.items.map((item) => item.str).join('').replace(/(\.|\?|\!|\:|\;|\n)/g, '$1\n');
      setTimeout(() => {
        const convertedContent = convertPDFToSlateJS(pageText);
        if (convertedContent.length > 0) {
          setPdfContent((prevContent) => [...prevContent, ...convertedContent]);
      }
       
      }, 1000);
    } catch (error) {
      // Handle any errors that occur during the asynchronous operations
      console.error('Error loading text content:', error);
    }
  }
  const debouncedPDFContent = useDebounce(pdfContent, 1000);
  useEffect(() => {
    if (debouncedPDFContent.length > 1) {
      setState((prev) => ({ ...prev, content: debouncedPDFContent }));
    }
  }, [debouncedPDFContent]);


  return (
    <>
      <Typography
        onClick={() => {
          setDefaultScript(null);
          openDrawer();
        }}
        sx={{
          '&:hover': { backgroundColor: '#d0551c  ' },
          whiteSpace: 'nowrap',
          fontSize: '.875rem',
          backgroundColor: '#fe6d29',
          fontFamily: 'inter',
          padding: '0.5rem 2rem',
          borderRadius: '.25rem',
          color: '#fff',
          cursor: 'pointer',
        }}
      >
        New Script
      </Typography>

      <Drawer
        anchor="top"
        open={open}
        onClose={closeDrawer}
        // disableBackdropTransition // Prevent background from being disabled
        PaperProps={{ style: { width: '100%', maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' } }}
        hideBackdrop
      >
        {open && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,

              zIndex: -99, // Behind the drawer content
            }}
            onClick={handleBackdropClick} // Close the drawer on backdrop click
          />
        )}

        <Box
          sx={(theme) => ({
            [theme.breakpoints.down('sm')]: {
              padding: '20px',
            },
          })}
          p={'40px'}
        >
          <Typography sx={{ fontSize: '1.25rem', fontFamily: 'inter' }}>
            {/* New Script */}
            {defaultScript ? 'Edit Script' : 'New Script'}
          </Typography>

          <Box mt={'20px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box display={'flex'} alignItems={'center'} gap={'15px'}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ width: '35px', height: '35px', backgroundColor: '#fe6d29', borderRadius: '50%' }}
              >
                <Typography sx={{ color: '#fff', fontFamily: 'inter' }}>1</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: '1rem', fontFamily: 'inter' }}>Import a script?</Typography>
                <Typography sx={{ fontSize: '.75rem', color: '#b2b3c4' }}>fdx / foundation / pdf / word / celtx / movie magic</Typography>
              </Box>
            </Box>
            <Box >
                <label htmlFor="file-input" style={{cursor: 'pointer'}}>
                  <Typography p={'0.5rem 2rem'} sx={{ cursor: 'pointer', backgroundColor: '#00017806', fontSize: '.875rem', fontFamily: 'inter' }} >Import</Typography>
                </label>
                <input
                  id="file-input"
                  type="file"
                  accept=".fdx, .docx, .pdf, .scrn"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
            </Box>
          </Box>
          {
            selectedFile &&
            <Box mt={'20px'} display={'flex'} 
              sx={{
                backgroundColor: 'orange',
                padding: '10px',
                borderRadius: '5px',
              }}
            alignItems={'center'} justifyContent={'space-between'}>
              <Box>
                <Typography sx={{ fontSize: '.875rem', fontWeight: 400, fontFamily: 'inter' }}>
                  You are about to import <b style={{color: 'white'}}>{getFileNameWithoutExtension(selectedFile?.name)}</b> in <b style={{color: 'white', textTransform: 'uppercase'}}>{getFileExtension(selectedFile?.name)}</b> format. You might need to format the content after import.
                  Because the content might not be formatted correctly.
                </Typography>
              </Box>
            </Box>
          }
         {fileContent &&  
         <div style={{display: 'none'}}>
          <Document
            file={fileContent}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={console.error}
            className="pdf-document"
          >
            {/* <Page pageNumber={pageNumber} onLoadSuccess={onPageLoadSuccess} /> */}
            {[...Array(numPages).keys()].map((pageIndex) => (
          <Page
            key={`page_${pageIndex + 1}`}
            pageNumber={pageIndex + 1}
            onLoadSuccess={onPageLoadSuccess}
          />
        ))}
          </Document>
        </div>}
          <Box mt={'20px'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box display={'flex'} alignItems={'center'} gap={'15px'}>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ width: '35px', height: '35px', backgroundColor: '#fe6d29', borderRadius: '50%' }}
              >
                <Typography sx={{ color: '#fff', fontFamily: 'inter' }}>2</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: '1rem', fontFamily: 'inter' }}>Additional information</Typography>
                <Typography sx={{ fontSize: '.75rem', color: '#b2b3c4' }}>
                  You can always change this later, feel free to skip any fields
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box ml={'50px'}>
            <Box mt={'10px'}>
              <Typography sx={{ fontSize: '.875rem' }}>Title</Typography>
              <input
                className="dash-scr-input light-bg dark-text"
                value={state?.title}
                onChange={(e) => setState({ ...state, title: e.target.value })}
              />
            </Box>

            <Box
              mt={'10px'}
              display={'flex'}
              alignItems={'center'}
              gap={'10px'}
              //    justifyContent={'space-between'}
            >
              {/* <Box width={'100%'}>
                <Typography sx={{ fontSize: '.875rem' }}>Subtitle</Typography>
                <input
                  className="dash-scr-input light-bg dark-text"
                  value={state?.subTitle}
                  onChange={(e) => setState({ ...state, subTitle: e.target.value })}
                />
              </Box> */}
              <Box width={'100%'}>
                <Typography sx={{ fontSize: '.875rem' }}>Written by</Typography>
                <input
                  className="dash-scr-input light-bg dark-text"
                  placeholder="Jim Bulian"
                  value={state?.writtenBy || user?.name}
                  onChange={(e) => setState({ ...state, writtenBy: e.target.value })}
                />
              </Box>
            </Box>

            <Box mt={'10px'} display={'flex'} alignItems={'center'} gap={'10px'}>
              <Box width={'70%'}>
                <Typography sx={{ fontSize: '.875rem' }}>Genre</Typography>
                <input
                  className="dash-scr-input light-bg dark-text"
                  value={state?.genre}
                  onChange={(e) => setState({ ...state, genre: e.target.value })}
                />
              </Box>
              <Box width={'30%'}>
                <Typography sx={{ fontSize: '.875rem' }}>Page Target</Typography>
                <input
                  className="dash-scr-input light-bg dark-text"
                  placeholder="110"
                  type="number"
                  value={state?.pageTarget}
                  onChange={(e) => setState({ ...state, pageTarget: e.target.value })}
                />
              </Box>
            </Box>

            <Box mt={'10px'}>
              <Typography sx={{ fontSize: '.875rem' }}>Logline</Typography>
              <input
                className="dash-scr-input light-bg dark-text"
                value={state?.logline}
                onChange={(e) => setState({ ...state, logline: e.target.value })}
              />
            </Box>
          </Box>

          <Box mt={'30px'} display={'flex'} sx={{
            float: 'right',
          }} justifyContent={'space-between'}>
            
            <Box display={'flex'} gap={'10px'}>
              <Box
                onClick={closeDrawer}
                p={'0.5rem 2rem'}
                sx={{ cursor: 'pointer', backgroundColor: '#00017806', '&:hover': { backgroundColor: '0001780d' } }}
              >
                <Typography sx={{ fontSize: '.875rem', fontFamily: 'inter' }}>Cancel</Typography>
              </Box>
              <Box
                onClick={() => {
                  if ((!updateLoading && !loading)) {
                    handleScript();
                  }
                }}
                p={'0.5rem 2rem'}
                sx={{
                  cursor: 'pointer',
                  backgroundColor: '#fe6d29',
                  borderRadius: '.25rem',
                  '&:hover': { backgroundColor: '#d0551c' },
                }}
              >
                <Typography sx={{ fontSize: '.875rem', fontFamily: 'inter', color: '#fff' }}>
                   Create script
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default forwardRef(NewScriptPoper);
